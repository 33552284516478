import {
  Box,
  Flex,
  VisuallyHidden,
  useColorModeValue as mode,
} from "@chakra-ui/react";
import { Logo } from "~/componentes/Logo";
import { NavContent } from "~/componentes/NavContent";
import * as React from "react";

export const Navbar = ({ company, navbar }) => {
  return (
    <Box
      as="header"
      height="16"
      bg={mode("white", "gray.800")}
      position="relative"
    >
      <Box
        height="100%"
        maxW="7xl"
        mx="auto"
        ps={{ base: "6", md: "8" }}
        pe={{ base: "5", md: "0" }}
      >
        <Flex
          as="nav"
          aria-label="Site navigation"
          align="center"
          justify="space-between"
          height="100%"
        >
          <Box as="a" href="/" rel="home">
            <VisuallyHidden>{company.name}</VisuallyHidden>
            <Logo
              company={company}
              h="6"
              iconColor={mode("blue.600", "blue.200")}
            />
          </Box>
          <NavContent.Desktop
            navbar={navbar}
            basecolor={company.basecolor}
            display={{ base: "none", md: "flex" }}
          />
          <NavContent.Mobile
            navbar={navbar}
            basecolor={company.basecolor}
            display={{ base: "flex", md: "none" }}
          />
        </Flex>
      </Box>
    </Box>
  );
};
