import { Image } from "@chakra-ui/react";
import * as React from "react";

export const Logo = (props) => {
  const { company } = props;
  return (
    <Image
      alt={`${company.name} - Logo`}
      objectFit="contain"
      boxSize={{ base: "5rem", md: "6rem", lg: "7.5rem" }}
      src={company.logoUrl}
    />
  );
};
