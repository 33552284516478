import {
  Box,
  Button,
  Center,
  HStack,
  Link,
  Stack,
  StackDivider,
  useColorModeValue as mode,
  useDisclosure,
} from "@chakra-ui/react";
import * as React from "react";
import { HiOutlineMenu, HiX } from "react-icons/hi";
import { NavLink } from "./NavLink";
import { NavList } from "./NavList";
import { NavListItem } from "./NavListItem";

const MobileNavContent = (props) => {
  const { navbar, basecolor } = props;
  const { isOpen, onToggle } = useDisclosure();
  return (
    <Box {...props}>
      <Center
        as="button"
        p="2"
        fontSize="2xl"
        color={mode("gray.600", "gray.400")}
        onClick={onToggle}
      >
        {isOpen ? <HiX /> : <HiOutlineMenu />}
      </Center>
      <NavList
        pos="absolute"
        insetX="0"
        bg={`${basecolor}.600`}
        top="64px"
        animate={isOpen ? "enter" : "exit"}
      >
        <Stack
          spacing="0"
          divider={<StackDivider borderColor="whiteAlpha.200" />}
        >
          {navbar.links.map((link, index) => (
            <NavListItem key={index}>
              <NavLink.Mobile href={link.href} target={link.target}>{link.label}</NavLink.Mobile>
            </NavListItem>
          ))}
          <NavListItem style={{ flex: "1" }}>
            <NavLink.Mobile href={navbar.actionUrl} target="_blank">
              {navbar.actionLabel}
            </NavLink.Mobile>
          </NavListItem>
        </Stack>
      </NavList>
    </Box>
  );
};

const DesktopNavContent = (props) => {
  const { navbar, basecolor } = props;
  return (
    <HStack spacing="8" align="stretch" {...props}>
      {navbar.links.map((link, index) => (
        <NavLink.Desktop key={index} href={link.href} target={link.target}>
          {link.label}
        </NavLink.Desktop>
      ))}
      <Button
        as={Link}
        height="16"
        rounded="0"
        href={navbar.actionUrl}
        target={navbar.actionTarget || "_blank"}
        colorScheme={basecolor}
        minW="10rem"
      >
        {navbar.actionLabel}
      </Button>
    </HStack>
  );
};

export const NavContent = {
  Mobile: MobileNavContent,
  Desktop: DesktopNavContent,
};
